<template>
    <v-container>
        <AConfirmation :isShow="isPending" @cancel="cancelSubmit" @confirm="submit"/>
        <ASuccessWithoutReload :api="this.api" @close="reloadPage"/>
        <v-skeleton-loader v-if="api.isLoading" type="table" ref="skeleton">
        </v-skeleton-loader>
        <ComponentTableReportCompanies/>
    </v-container>
</template>
  
<script>
import ComponentTableReportCompanies from '../../components/table/ComponentTableReportCompanies.vue';
// import ComponentPrototypeReportCompanies from '../../components/developer/prototype/ComponentPrototypeReportCompanies.vue';
import { mapState } from 'vuex'
import ASuccessWithoutReload from '../../components/common/ASuccessWithoutReload.vue';
export default {
    components:{
    ASuccessWithoutReload,
    ComponentTableReportCompanies,
    // ComponentPrototypeReportCompanies,
},
    computed: mapState({
    //
    }),
    props:[
    //
    ],
    data: () => ({
        data:null,
        name:"companies report.csv",
        search:"",
        downloadData:null,
        dataHeader:[
            {
                text:'Company Id',
                value:'company_id',
            },
            {
                text:'Company name',
                value:'company_name'
            },
            {
                text:'Company type',
                value:'company_acc_category'
            },
            {
                text:'Coupon',
                value:'coupon'
            },
            {
                text:'User Id',
                value:'user_id'
            },
            {
                text:'First name',
                value:'fname',
            },
            {
                text:'Last name',
                value:'lname'
            },
            {
                text:'Email',
                value:'email',
            },
            {
                text:'Mobile',
                value:'mobile'
            },
            {
                text:'Status',
                value:'user_status',
            },
            {
                text:'Email Status',
                value:'email_verified_at',
            },
            {
                text:'Account',
                value:'acc_category'
            },
            {
                text:'Role',
                value:'role',
            },
            {
                text:'AAE Role',
                value:'aae_role',
            },
            {
                text:'Minutes',
                value:'minutes',
            },
            {
                text:'Created Date',
                value:'user_created_at'
            },
            // {
            //     text:'Invoice Date',
            //     value:'invoice_created_at'
            // }
        ],
        AllowedRedirectRole:[
            "vision_admin",
            "operation",
            "sales_manager",
            "developer",
        ],
        userStatusOptions:[
            {
                text:'Active',
                value:"1"
            },
            {
                text:'Deactive',
                value:"0",
            }
        ],
        isPending:false,
        options:{
            itemsPerPage:20,
            page:1,
            sortBy:[],
            search:'',
        },
        api:
            {
                isLoading : false,
                isError:false,
                error:null,
                url:null,
                isSuccesful:false,
                success:null,
            }
    }),
    created() {
        this.api.callbackReset = () => {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) => {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp) => {
            if(resp.class==="updateUserStatus" ||resp.class==="updateUserEmailVerification"){
                this.api.isSuccesful = true;
                this.api.success = "Succesfully Update Client Detail"
            }
            if(resp.class==="getCompanyReportServerPaginate"){
                this.data = resp.data;
                this.downloadData =  this.data.map(obj=>({
                "Company":obj.company_name,
                "Company type":obj.company_acc_category,
                "Coupon":obj.coupon,
                "User Id":obj.user_id,
                "First name":obj.fname,
                "Last name":obj.lname,
                "Email":obj.email,
                "Contact number":obj.mobile,
                "User type":obj.acc_category,
                "Status":(obj.user_status==1)?"Verified":"Not Verified",
                "Email Status":(obj.email_verified_at!=null)?"Verified":"Not Verified",
                "Role":obj.role,
                "AAE role":obj.aae_role,
                "Minutes":obj.minutes,
                "Subscribe Date":this.convertTimeZone(obj.user_created_at),
            }))
            }
            this.api.isLoading = false;
            this.api.isError = false;
        }

    },
    mounted() {
        // this.fetch();
    },
    methods: {
        fetch(){
            let fetchCompaniesReportApi = this.fetchCompaniesReport();
            this.$api.fetch(fetchCompaniesReportApi);
        },
        fetchCompaniesReport(){
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API+'/report/company/paginate?page='+this.options.page+"&sortBy="+this.options.sortBy+"&sortDesc="+this.options.sortDesc+"&search="+this.options.search+"&rowsPerPage="+this.options.itemsPerPage;
            return tempApi;
        },
       
        validateInput(){
            this.isPending=true;
        },
        cancelSubmit(){
            this.isPending = false;
        },
        submit(){
            this.isPending = false;
            let reApplyCouponApi = this.reApplyCoupon();
            this.$api.fetch(reApplyCouponApi);
        },
        convertTimeZone(time){
            return this.$moment(time).format('LLL');
        },
        activateAccountStatus(userId,status){
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "PUT";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/users/status/id/"+userId;
            tempApi.params = {
                'status':status,
            };
            this.$api.fetch(tempApi);
        },
        activateEmailVerification(userId,status){
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "PUT";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/users/email/id/"+userId;
            tempApi.params={
                'status':status,
            }
            this.$api.fetch(tempApi);

        },
        checkIfCanAccessSubscriptionHistory() {
           if(this.$store.getters.getFeatures.subscriptionHistory.status==1 || (this.$store.getters.getFeatures.subscriptionHistory.beta==1 && this.$store.getters.getRole.includes('developer'))) {
            return true;
           }
           return false;
        },
        reloadPage(){
            location.reload();
        }
    }
}
</script>