<template>
    <v-container
        class="px-0">
        <AConfirmation :isShow="isPending" @cancel="cancelSubmit" @confirm="submit"/>
        <ASuccessWithoutReload :api="this.api" @close="reloadPage" @click:outside="reloadPage"/>
        <v-skeleton-loader v-if="api.isLoading && isFirstLoading" type="table" ref="skeleton">
        </v-skeleton-loader>
        <v-data-table
            v-else 
            :items="data.data"
            :lazy="true"
            :loading="api.isLoading"
            :options.sync="options"
            :items-per-page:sync="options.itemsPerPage"
            :headers="dataHeader"
            :server-items-length="data.total"
            :sort-desc:sync="options.sortDesc"
            :sort-by.sync="options.sortBy"
            class="elevation-1 custom-table"
            :footer-props="{
                itemsPerPageOptions:[20,40,100],
                showCurrentPage:true,
            }"
            dense 
            @update:options="onPaginationUpdate">
            <template
                v-slot:item.index="{item}">
                <span>
                    {{ ((options.page-1) * options.itemsPerPage) + (data.data.indexOf(item)+1) }}
                </span>
            </template>
            <template
                v-slot:top>
                <v-toolbar
                    flat 
                    class="text-h4 pt-4"
                    height="auto">
                        <v-row
                            no-gutters>
                            <v-col
                                class="mt-3 mx-3">
                                <v-row
                                    class="mb-3">
                                    <v-toolbar-title
                                        class="font-weight-bold text-h4 mt-3 ml-3">
                                        Company Report
                                    </v-toolbar-title>
                                </v-row>
                                <v-row>
                                    <v-col
                                        class="pr-0"
                                        cols="6">
                                        <v-select
                                            style="border-radius: 6px 0px 0px 6px;"
                                            :items="filterHeaders"
                                            dense 
                                            outlined
                                            v-model="selectedFilterHeaders"
                                            item-text="text"
                                            item-value="value">
                                        </v-select>
                                    </v-col>
                                    <v-col
                                        class="pl-0"
                                        cols="6">
                                        <v-text-field
                                            style="border-radius: 0px 6px 6px 0px;"
                                            v-model="search"
                                            dense
                                            outlined 
                                            label="Search">
                                        </v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col
                                        class="pt-0">
                                        <div
                                            class="d-flex justify-space-between pt-0 px-0"
                                            style="margin-top:-20px;margin-bottom:20px">
                                            <div
                                                class="d-flex justify-end">
                                                <v-btn
                                                    @click="downloadReport"
                                                    small
                                                    plain
                                                    color="primary"
                                                    class="mx-2">
                                                    Download Report
                                                </v-btn>
                                            </div>
                                            <div>
                                                <v-btn
                                                    color="primary"
                                                    small
                                                    style="border-radius:4px 0px 0px 4px"
                                                    @mouseover="hovered.button1=true"
                                                    @mouseleave="hovered.button1=false"
                                                    outlined
                                                    @click="refreshPage"
                                                    :class="hovered.button1?'active':''"
                                                    >
                                                    Refresh

                                                </v-btn>
                                                <v-btn
                                                    color="primary"
                                                    small
                                                    @mouseover="hovered.button2=true"
                                                    style="border-radius:0px"
                                                    @mouseleave="hovered.button2 = false"
                                                    @click="resetValue"
                                                    :class="hovered.button2?'active':''"
                                                    outlined >
                                                Reset Filter
                                                </v-btn>
                                                <v-btn
                                                    style="border-radius: 0px 4px 4px 0px;"
                                                    :class="(hovered.button3||isFiltering)?'active':''+'py-0'  "
                                                    color="primary"
                                                    elevation="0"
                                                    small
                                                    @mouseover="hovered.button3 = true"
                                                    @mouseleave="hovered.button3=false"
                                                    outlined
                                                    @click="FilterValue">
                                                    Filter
                                                </v-btn>
                                            </div>
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                            <!-- <v-row
                                class="pt-3">
                                <v-col
                                    class="mt-2 pb-0">
                                    <v-autocomplete
                                        dense
                                        outlined
                                        clearable
                                        label="Subscription status"
                                        :items="optionsSubsriptionStatus"
                                        v-model="options.subscriptionStatus">
                                    </v-autocomplete>
                                </v-col>
                                
                            </v-row> -->
                            <!-- <v-row class="">
                                <v-col
                                    class="py-0">
                                    <v-autocomplete
                                        dense 
                                        outlined
                                        clearable
                                        item-text="username"
                                        item-value="id"
                                        :items="csOptions"
                                        v-model="options.csPIC"
                                        label="Customer service">

                                    </v-autocomplete>
                                </v-col>
                                <v-col
                                    class="py-0">
                                    <v-autocomplete
                                    item-text="coupon"
                                    v-model="options.couponSearch"
                                    item-value="coupon"
                                    :items="coupons"
                                    label="Filter coupon"
                                    dense
                                    outlined
                                    clearable
                                    >
                                    </v-autocomplete>
                                </v-col>
                            </v-row> -->
                            <!-- <v-row
                                class="">
                                <v-col
                                    class="pt-3">
                                    <v-text-field
                                        v-model="search"
                                        dense
                                        label="Search"
                                        outlined
                                        @input="handleSearchInput"
                                        clearable>
                                    </v-text-field>
                                </v-col>
                            </v-row> -->
                          
                </v-toolbar>
            </template>
            <template
                v-slot:item.user_created_at="{item}">
                <span
                    v-if="item.user_created_at!=null">
                    {{ convertTimeZone(item.user_created_at) }}
                </span>
                <span>
                    -
                </span>
            </template>
            <template
                v-slot:item.welcome_guide_sent="{item}">
                <span
                    v-if="item.welcome_guide_sent!=null">
                    {{ convertTimeZone(item.welcome_guide_sent) }}
                </span>
                <span>
                    -
                </span>
            </template>
            <template
                v-slot:item.welcome_wa_sent="{item}">
                <span
                    v-if="item.welcome_wa_sent!=null">
                    {{ convertTimeZone(item.welcome_wa_sent) }}
                </span>
                <span>
                    -
                </span>
            </template>
            <template   
                v-slot:item.action="{item}">
                <v-row>
                    <v-col>
                        <v-menu
                            offset-y>
                            <template
                                v-slot:activator="{ on,attrs}">
                                <v-btn
                                    small
                                    v-bind="attrs"
                                    v-on="on"
                                    color="primary">
                                    Actions
                                    <v-icon>
                                        <!-- {{actionDrop?'mdi-chevron-up':'mdi-chevron-down'}} -->
                                        mdi-chevron-up
                                    </v-icon>
                                </v-btn>
                            </template>
                            <v-list>
                                <v-list-item
                                    style="font-size:16px"
                                    v-for="(data,index) in actions"
                                    :key="index"
                                    @click="executeActions(data.action,item)">
                                        {{ data.name }}
                                </v-list-item>
                            </v-list>


                        </v-menu>
                    </v-col>
                </v-row>
            </template>
            <template 
                v-slot:item.user_status="{item}">
                <div>
                    <v-btn
                        @click="activateAccountStatus(item.user_id,0)"
                        width="100"
                        small
                        elevation="0"
                        color="green white--text"
                        v-if="item.user_status==1">
                        Active
                    </v-btn>    
                    <v-btn
                        @click="activateAccountStatus(item.user_id,1)"
                        width="100"
                        elevation="0"

                        small
                        color="red white--text"
                        v-else>
                        Deactive
                    </v-btn>

                </div>
            </template>
            <template
                v-slot:item.fname="{item}">
                <!-- <span
                    @click="()=>{
                        navigator.clipboard.writeText(item.fname);
                    }">
                    {{ item.fname }}
                </span> -->
                <ACopyAbleText
                    :text="item.fname">
                </ACopyAbleText>
            </template>
            <template
                v-slot:item.lname="{item}">
                <ACopyAbleText
                    :text="item.lname">
                </ACopyAbleText>
            </template>
            <template
                v-slot:item.email="{item}">
                <ACopyAbleText
                    :text="item.email">

                </ACopyAbleText>
            </template>
            <template
                v-slot:item.mobile="{item}">
                <ACopyAbleText
                    :text="item.mobile">
                </ACopyAbleText>
            </template>
            <template
                v-slot:item.acc_category="{item}">
                <ACopyAbleText
                    :text="item.acc_category">
                </ACopyAbleText>
            </template>
            <template
                v-slot:item.company_acc_category="{item}">
                <ACopyAbleText
                    :text="item.company_acc_category">
                </ACopyAbleText>
            </template>
            <template
                v-slot:item.user_id="{item}">
                <ACopyAbleText
                    :text="item.user_id">
                </ACopyAbleText>
            </template>
            <!-- <template> -->
                
            <!-- </template> -->

            <template
                v-slot:item.company_name="{item}">
                <router-link
                    :to="{name:'PageCompaniesDetail',params:{id:item.company_id}}">
                    {{ item.company_name }}
                </router-link>
            </template>
            <template
                v-slot:item.subscription_created_at="{item}">
                <span
                    v-if="item.subscription_created_at!=null">
                    {{ convertTimeZone(item.subscription_created_at) }}
                </span>
                <span
                    v-else>
                    -
                </span>
            </template>
            <template
                v-slot:item.hasInvoice={item}>
                <span
                    v-if="item.hasInvoice==true">
                    Recorded
                </span>
                <span
                v-else>
                    Not recorded
                </span>
            </template>
            <template
                v-slot:item.stripe_status="{item}">
                <div>
                    <v-chip
                        outlined
                        v-if="item.stripe_status.toLowerCase()==='canceled'"
                        color="red white--text"
                        class="">
                        {{ item.stripe_status.charAt(0).toUpperCase() + item.stripe_status.slice(1) }}
                        </v-chip>
                    <v-chip
                        outlined
                        color="orange white--text"
                        v-if="item.stripe_status.toLowerCase()==='incomplete'">
                        {{ item.stripe_status.charAt(0).toUpperCase() + item.stripe_status.slice(1) }}
                    </v-chip>
                    <v-chip
                        outlined
                        v-if="item.stripe_status.toLowerCase()==='active'"
                        color="green white--text">
                        {{ item.stripe_status.charAt(0).toUpperCase() + item.stripe_status.slice(1) }}
                    </v-chip>

                 </div> 
            </template>
            <template   
                v-slot:item.cs_fname="{item}">
                <span
                    v-if="item.cs_id!=null">
                    {{ item.cs_fname+" "+item.cs_lname }}
                </span>
                <span
                    v-else>
                    -
                </span>
            </template>
            <template
                v-slot:item.email_verified_at="{item}">
                <div>
                    <v-btn
                        @click="activateEmailVerification(item.user_id,0)"
                        width="100"
                        small
                        elevation="0"
                        color="green white--text"
                        v-if="item.email_verified_at!=null">
                        <span>
                            Active
                        </span>
                    </v-btn>    
                    <v-btn
                        @click="activateEmailVerification(item.user_id,1)"
                        width="100"
                        small
                        elevation="0"
                        color="red white--text"
                        v-else>
                        <span>
                            Deactive
                        </span>
                    </v-btn>

                </div>
            </template>

        </v-data-table>
    </v-container>
</template>
  
<script>
import { mapState } from 'vuex'
import * as XLSX from 'xlsx'
import ASuccessWithoutReload from '../../components/common/ASuccessWithoutReload.vue';

export default {
    components:{
        ASuccessWithoutReload,
    //
    },
    computed: mapState({
    }),
    props:[
    //
    ],
    data: () => ({
        isFirstLoading:true,
        name:"companies report.csv",
        data:null,
        coupons:null,
        isProcessingSearch:false,
        downloadData:null,
        search:"",
        optionsSubsriptionStatus:
        ['Active','Incomplete','Canceled',],
        csOptions:null,
        options:{
            itemsPerPage:20,
            page:1,
            sortBy:[],
            search:'',
            couponSearch:'',
            subscriptionStatus:'',
            csPIC:'',
        },
        hovered:{
            button1:false,
            button2:false,
            button3:false,
        },
        selectedFilterHeaders:"all",
        filterHeaders:[
            {
                text:'All',
                value:'all'
            },
            {   
                text:"Company",
                value:"companies.name"
            },
            {
                text:'Subscription',
                value:'subscriptions.stripe_status'
            },
            {
                text:"First name",
                value:"users.fname",
            },
            {
                text:"Last name",
                value:'users.lname',
            },
            {
                text:'Email',
                value:'users.email'
            },
            {
                text:"Mobile",
                value:'users.mobile'
            },
            {
                text:"Coupon",
                value:"coupon",
            },
        ],
        actions:[
            {
                name:'Update company detail',
                action:'PageCompaniesUpdate',
            },
            {
                name:'Update user detail',
                action:'PageUserUpdate',
            },
            {
                name:'Batch company form',
                action:'PageUpdateFormBatch'
            },
            {
                name:'Batch user form',
                action:'PageUpdateUserFormBatch'
            }

        ],
        dataHeader:[
        {
                text:'Company Id',
                value:'company_id',
            },
            {
                text:'Company name',
                value:'company_name'
            },
           
            {
                text:'Stripe subscription status',
                value:'stripe_status',
                sortable:false,
            },
            
            {
                text:'User Id',
                value:'user_id'
            },
            {
                text:'First name',
                value:'fname',
            },
            {
                text:'Last name',
                value:'lname'
            },
            {
                text:'Email',
                value:'email',
            },
            {
                text:'Mobile',
                value:'mobile'
            },
            {
                text:'TaxPOD account status',
                value:'user_status',
            },
            {
                text:'Email status',
                value:'email_verified_at',
            },
            {
                text:'Company type',
                value:'company_acc_category'
            },
            {
                text:'Account',
                value:'acc_category'
            },
            {
                text:'Role',
                value:'role',
            },
            {
                text:'AAE role',
                value:'aae_role',
                sortable:false,
            },
            {
                text:'Minutes',
                value:'minutes',
                sortable:false,
            },
            // {
            //     text:'Invoice',
            //     value:'hasInvoice',
            //     sortable:false,
            // },
            {
                text:'Created date',
                value:'user_created_at'
            },
            {
                text:'Subscription start date',
                value:'subscription_created_at'
            },
            {
                text:'Customer service',
                value:'cs_fname'
            },
            {
                text:'Coupon',
                value:'coupon'
            },
            {
                text:'Welcome guide sent date',
                value:'welcome_guide_sent'
            },
            {
                text:'Welcome WA Sent date',
                value:'welcome_wa_sent'
            },
            {
                text:'Action',
                value:'action'
            }
        ],
        isPending:false,
        isLoadingDownloadData:true,
        isFiltering:false,
        api:
            {
                isLoading : false,
                isError:false,
                error:null,
                url:null,
                isSuccesful:false,
                success:null,
            }
    }),
    created() {
        this.api.callbackReset = () => {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) => {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp) => {
            if(resp.class==="updateUserStatus" ||resp.class==="updateUserEmailVerification"){
                this.api.isSuccesful = true;
                this.api.success = "Succesfully Update Client Detail"
            }
            if(resp.class==='getStaffListByRole') {
                this.csOptions = resp.data;
            }
            if(resp.class==="getCompanyReport")
            {
                this.downloadData = resp.data.map(obj=>({
                "Company":obj.company_name,
                "Company type":obj.company_acc_category,
                "Coupon":obj.coupon,
                "User Id":obj.user_id,
                "First name":obj.fname,
                "Last name":obj.lname,
                "Email":obj.email,
                "Contact number":obj.mobile,
                "User type":obj.acc_category,
                "Status":(obj.user_status==1)?"Verified":"Not Verified",
                "Email Status":(obj.email_verified_at!=null)?"Verified":"Not Verified",
                "Role":obj.role,
                "AAE role":obj.aae_role,
                "Minutes":obj.minutes,
                "Subscribe Date":this.convertTimeZone(obj.user_created_at),
                "Welcome Guide Sent":this.convertTimeZone(obj.welcome_guide_sent),
                "Welcome WA Sent":this.convertTimeZone(obj.welcome_wa_sent),
                }));
                this.isLoadingDownloadData = false;
            }
            if(resp.class==="getCompanyReportServerPaginate"){
                this.data = resp.data;
                if(this.isFirstLoading){
                    this.downloadData = resp.data.data.map(obj=>({
                "Company":obj.company_name,
                "Company type":obj.company_acc_category,
                "Coupon":obj.coupon,
                "User Id":obj.user_id,
                "First name":obj.fname,
                "Last name":obj.lname,
                "Email":obj.email,
                "Contact number":obj.mobile,
                "User type":obj.acc_category,
                "Status":(obj.user_status==1)?"Verified":"Not Verified",
                "Email Status":(obj.email_verified_at!=null)?"Verified":"Not Verified",
                "Role":obj.role,
                "AAE role":obj.aae_role,
                "Minutes":obj.minutes,
                "Subscribe Date":this.convertTimeZone(obj.user_created_at),
                "Welcome Guide Sent":this.convertTimeZone(obj.welcome_guide_sent),
                "Welcome WA Sent":this.convertTimeZone(obj.welcome_wa_sent),
                }));
                }
                this.isFirstLoading = false;
              
            }
            if(resp.class==="getAllAvailableCoupon"){
                this.coupons = resp.data;
            }
            if(resp.class === 'getCompanyReportServerPaginateDownload') {
                try{
                    let worksheet = XLSX.utils.json_to_sheet(resp.data);
                    let workbook = XLSX.utils.book_new();
                    XLSX.utils.book_append_sheet(workbook,worksheet,'Sheet1');
                    const binaryData = XLSX.write(workbook,{bookType:'xlsx',type:'array'});
                    const blob = new Blob([binaryData], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                    const url = window.URL.createObjectURL(blob);
                    let link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download','compant.xlsx');
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    URL.revokeObjectURL(link.href);
                    // const blob = new Blob([resp.data], {
                    //     type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                    // });
                    // let url = URL.createObjectURL(blob);
                    // let a = document.createElement("a");
                    // a.style.display = "none";
                    // a.href = url;
                    // a.download = 'company.xlsx';
                    // document.body.appendChild(a);
                    // a.click();
                    // window.URL.revokeObjectURL(url);
                }catch(error) {
                    alert(error)
                }

            }
            this.api.isLoading = false;
            this.api.isError = false;
        }

    },
    mounted() {
        this.fetch();
    },
    methods: {
        fetch(){
            let filter = this.$store.getters.getFilterCompanyReport;
            this.selectedFilterHeaders = this.$_.clone(filter.headers);
            this.isFiltering = this.$_.clone(filter.isFiltering);
            this.search = this.$_.clone(filter.value);
            if(this.search!="") {
                this.isFiltering = true;
            }
            let fetchCompaniesPaginateApi = this.fetchCompaniesPaginate();
            let fetchAvailableCouponApi = this.fetchAvailableCoupon();
            let fetchCSOptionsApi = this.fetchCSOptions();
            let fetchCompaniesReportApi = this.fetchCompaniesReport();
            this.$api.fetch(fetchCompaniesPaginateApi);
            if(this.isFirstLoading){
                this.$api.fetch(fetchAvailableCouponApi);
                this.$api.fetch(fetchCompaniesReportApi);
                this.$api.fetch(fetchCSOptionsApi);
            }
        },
        fetchCSOptions() {
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/role/staff/customer_service";
            return tempApi;
        },
        downloadReport() {
            let fetchCompaniesPaginate = this.fetchCompaniesPaginate();

            fetchCompaniesPaginate.url = fetchCompaniesPaginate.url+"&download=true"
            this.$api.fetch(fetchCompaniesPaginate);
        },
        fetchCompaniesPaginate(){
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API+'/report/company/paginate?page='+this.options.page+"&search="+this.selectedFilterHeaders+"&searchValue="+this.search+"&rowsPerPage="+this.options.itemsPerPage;
            return tempApi;
        },
        fetchCompaniesReport(){
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API+'/report/company';
            return tempApi;
        },
        fetchAvailableCoupon(){
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/coupons/available";
            return tempApi;
        },
        refreshPage() {
            this.$api.fetch(this.fetchCompaniesPaginate());
        },
        resetValue() {
            let tempFilter = {
                'headers':'all',
                'value':'',
                'isFiltering':false,
            };
            this.$store.dispatch('updateFilterCompanyReport',tempFilter);
            this.search ="";
            this.selectedFilterHeaders = "all";
            this.isFiltering = false;
            this.$api.fetch(this.fetchCompaniesPaginate());
        },
       
        validateInput(){
            this.isPending=true;
        },
        cancelSubmit(){
            this.isPending = false;
        },
        submit(){
            this.isPending = false;
            let reApplyCouponApi = this.reApplyCoupon();
            this.$api.fetch(reApplyCouponApi);
        },
        handleSearchInput(){
            setTimeout(()=>{
                this.options.search = this.search;
            },1000);
        },
        onPaginationUpdate(pagination){

            if(this.isFirstLoading){
                this.isFirstLoading = false;
            }
            else{
                this.options = this.$_.clone(pagination);
                this.fetch();
            }
        },
        convertTimeZone(time){
            return this.$moment(time).format('LLL')
        },
        activateAccountStatus(userId,status){
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "PUT";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/users/status/id/"+userId;
            tempApi.params = {
                'status':status,
            };
            this.$api.fetch(tempApi);
        },
        activateEmailVerification(userId,status){
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "PUT";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/users/email/id/"+userId;
            tempApi.params={
                'status':status,
            }
            this.$api.fetch(tempApi);

        },
        clearInput(){
            this.search="";
        },
        reloadPage(){
            this.api.isSuccesful = false;

            this.fetch();
        },
        FilterValue() {
            this.isFiltering = true;
            let tempFilter = {
                'headers':this.selectedFilterHeaders,
                'value':this.search,
                'isFiltering':this.isFiltering,
            };
            this.$store.dispatch('updateFilterCompanyReport',tempFilter);
            this.$api.fetch(this.fetchCompaniesPaginate());
        },
        executeActions(action,company){
            if(['PageUserUpdate','PageCompaniesUpdate','PageUpdateFormBatch'].includes(action)) {
                this.$router.push({
                    name:action,
                    params:{
                        id:company.company_id,
                    },
                })
            }
            if(['PageUpdateUserFormBatch'].includes(action)) {
                this.$router.push({
                    name:action,
                });
            }
            
        },
    }
}
</script>
<style
    scoped>
    .active {
        background-color:#00688F;
        font-style:bold;
        color: white!important;
    }
    v-btn {
        transition:all 0.3s ease
    }
</style>